<template>
  <div class="hello container">
      <vs-row
        class="vertical-center"
        vs-align="flex-start"
        vs-type="flex" vs-justify="center" vs-w="12" vs-sm="12" vs-xs="12">
          <vs-col id="main-col" vs-justify="center" vs-align="center" vs-w="3" vs-sm="12" vs-xs="12">
            <img class="logo" src="./../assets/logo.png">

          <div v-if="!convert">
              <vs-card id="main-card">
                <div>
                  <span>
                    Конвертируйте плейлисты из Spotify в Яндекс.Музыку максимально легко и быстро.
                    <br><br>
                    Просто вставьте ссылку на публичный плейлист из Spotify, в поле ниже:
                  </span>
                </div>
                <div slot="footer">
                    <div class="centerx default-input">
                      <vs-input size="large" class="inputx" placeholder="https://open.spotify.com/playlist/0Id3yPPLcHZ9ifcui7hT2v" v-model="url"/>
                    </div>
                </div>
              </vs-card>
              <vs-button v-if="url.length > 0" @click="go()" id="convert" class="vs-con-loading__container"  color="primary" type="filled" ref="loadableButton">Конвертировать</vs-button>
          </div>

          <transition name="slide-fade">
          <div v-if="convert && finish == false">
              <vs-card id="main-card">
                <div slot="media">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12">
                      <img :src="playlist.image">
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12" style="padding-left: 10px;">
                      <div>
                        {{ playlist.name }}
                        <vs-divider color="primary">{{ playlist.track_count }} треков</vs-divider>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-card>
              <div id="div-with-loading" class="vs-con-loading__container" style="width: 40px;height: 40px;margin-left: auto;margin-right: auto;"></div>

          </div>
          <div v-if="finish">
              <vs-card id="main-card">
                <div slot="media">
                  <vs-row>
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12">
                        <img :src="tracklist_cover">
                      </vs-col>
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" vs-sm="12" vs-xs="12" style="padding-left: 10px;">
                        <div>
                          {{ tracklist_name }}
                          <vs-divider color="primary"></vs-divider>
                          <vs-button @click="downloadCover()" line-origin="left" color="rgb(250, 145, 92)" type="line">Скачать обложку</vs-button>
                        </div>
                      </vs-col>
                    </vs-row>
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" vs-sm="4" vs-xs="4">
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" vs-sm="12" vs-xs="12" style="padding-left: 10px;">
                      <div id="finish_text">
                        <p>
                          <textarea v-html="tracklist" class="tracklist" ref="tracklist">
                          </textarea>
                        </p>
                        <vs-divider/>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                <vs-button @click="openUrl()" id="convertend" color="primary" type="filled">Скопировать и добавить треки</vs-button>
                <vs-alert v-if="showHelp" active="true" color="warning" style="margin-top: 10px; height: auto;">
                  <p>
                    Если ничего не происходит при нажатии на кнопку, выделите текст в ручную и вставьте на этой странице: <a href="https://music.yandex.ru/import/" target="_blank">https://music.yandex.ru/import/</a>
                  </p>
                </vs-alert>
              </vs-card>
          </div>
          </transition>

          <vs-alert v-if="error" title="Ошибка" active="true" color="danger" style="margin-top: 30px; height: auto;">
            К сожалению, мы не можем получить данные из плейлиста, возможно он находится в закрытой категории.
          </vs-alert>

            <div v-if="!finish" style="margin-top: 60px;">
              <a href="https://www.tinkoff.ru/sl/4TnDzaj7NMz" target="_blank">
                <vs-button line-origin="left" color="rgb(250, 145, 92)" type="line">Поддержать проект</vs-button>
              </a>
              <vs-button @click="popupApi=true" class="api-button" line-origin="left" color="rgb(250, 145, 92)" type="line">API</vs-button>
              <vs-button @click="popupContact=true" line-origin="left" color="rgb(250, 145, 92)" type="line">Контакты</vs-button>

              <div style="margin-top: 20px;">
                <a href="https://vk.spotya.ru/" target="_blank" class="botml">
                  <vs-button line-origin="left" color="rgb(250, 145, 92)" type="line">Плейлисты из VK</vs-button>
                </a>
                <a href="https://yt.spotya.ru/" target="_blank" class="botml">
                  <vs-button line-origin="left" color="rgb(250, 145, 92)" type="line">Плейлисты из YT Music</vs-button>
                </a>
              </div>

              <vs-popup class="holamundo" title="API" :active.sync="popupApi" icon-close="Закрыть">
                <p>
                  У нас есть простенькое API. Если хотите можете им пользоваться.
                </p>
                  <br>
                <p>
                    Все запросы слать на <b>https://spotya.ru/api.php</b><br>
                    Принимаются только <b>POST</b> запросы.
                </p>
                <vs-alert title="Получить название, обложку и кол-во треков в плейлисте" active="true" color="dark" style="margin-top: 30px; height: auto;">
                  <b>Параметры:</b> <br>
                    <i>type: 'poster'</i><br>
                    <i>url: <b>полная ссылка на плейлист</b></i><br><br>
                    <img class="logo" src="./../assets/api_poster.png" style="width: 100%;">
                </vs-alert>
                <vs-alert title="Получить список треков из плейлиста" active="true" color="dark" style="margin-top: 30px; height: auto;">
                  <b>Параметры:</b> <br>
                    <i>type: 'playlist'</i><br>
                    <i>url: <b>полная ссылка на плейлист</b></i><br><br>
                    Возвращается массив треков.
                </vs-alert>
              </vs-popup>

              <vs-popup class="holamundo" title="Контакты" :active.sync="popupContact" icon-close="Закрыть">
                <p>
                  <b>Вконтакте:</b> https://vk.com/umnikone <br>
                  <b>Telegram:</b> @UmnikOne
                </p>
              </vs-popup>
            </div>
            <div v-else>
                <vs-button @click="popupHow=true" line-origin="left" color="rgb(250, 145, 92)" type="line" style="margin: 0px;">Как добавить плейлист к себе</vs-button>
                <vs-button @click="checkImportModal=true" line-origin="left" color="rgb(250, 145, 92)" type="line" style="margin: 5px;">Проверить импорт на отсутствующие треки</vs-button>
              <vs-popup class="holamundo" title="Как добавить плейлист к себе?" :active.sync="popupHow" icon-close="Закрыть">
                <p>
                  После того, как вы нажали кнопку "Скопировать и добавить треки", вас автоматически перекинет на следующую страницу.<br>На ней, вы должны вставить список треков в форму и нажать кнопку "Найти".<br>
                  <i>Текст копируется автоматически, поэто можете сразу вставлять текст в форму.</i>
                  <br><br>
                  <img class="logo" src="./../assets/spotya_how1.png" style="width: 100%;">
                  <br><br>
                  После того, как поиск закончен, вы можете нажать кнопку "Сохранить в плейлист" и внизу ввести название своего плейлиста. Далее нажать кнопку "Ender". И всё, ваш плейлист готов.
                  <br><br>
                  <img class="logo" src="./../assets/spotya_how2.png" style="width: 100%;">
                </p>
              </vs-popup>
              <vs-popup class="holamundo" title="Проверить импорт" :active.sync="checkImportModal" icon-close="Закрыть">
                <div v-if="!checkImportShowFields && !checkImportEnd">
                  <div>
                    <p>После импорта треков, вы получите ссылку вида - https://music.yandex.ru/import/<b>62291ee68a36c939a36d2c47</b>
                    <br><br>
                    Вставьте ссылку, в поле ниже.
                    </p>
                  </div>
                 <vs-input size="large" class="inputx" placeholder="https://music.yandex.ru/import/62291ee68a36c939a36d2c47" v-model="checkImportId"/>
                 <vs-button @click="generateCheckId()" id="checkImport" class="vs-con-loading__container"  color="primary" type="filled" ref="loadableButton">Проверить</vs-button>
                </div>
                <div v-if="checkImportShowFields && !checkImportEnd">
                <p>
                  Перейдите по ссылке: <a :href="'https://music.yandex.ru/handlers/import.jsx?code=' + checkImportId" target="_blank">https://music.yandex.ru/handlers/import.jsx?code={{ checkImportId }}</a><br>
                  И скопируйте всё содержимое оттуда, в поле ниже. После этого, нажмите кнопку.<br><br>
                </p>
                 <textarea id="checkArea" v-model="checkImportJson" ref="checkImportJson"></textarea>
                 <vs-button @click="checkImport()" id="checkImport" class="vs-con-loading__container"  color="primary" type="filled" ref="loadableButton">Проверить</vs-button>
                </div>
                <p v-if="checkImportEnd">
                 <b>Не найдено треков:</b> {{ checkImportError.length }}<br>
                </p>
                <div v-if="checkImportError.length > 0">
                 <i><small>Возможно, Яндекс хранит имена по другому и добавил их под другими именами, проверьте ещё в ручную.</small></i>
                 <vs-divider/>
                 <b>Список треков, которые не попали в плейлист:</b>
                 <p v-for="err in checkImportError" :key="err">
                   {{ err }}
                 </p>
                </div>
              </vs-popup>
            </div>
          </vs-col>
          
      </vs-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      url: '',
      convert: false,
      backgroundLoading:'primary',  
      playlist: {},
      finish: false,
      finish_images: [],
      finish_count: 0,
      finish_link: '',
      error: false,
      feed: [],
      last: false,
      popupApi:false,
      popupHow:false,
      popupContact:false,
      checkImportModal: false,
      checkImportId: '',
      checkImportShowFields: false,
      checkImportJson: '',
      checkImportSuccess: 0,
      checkImportError: [],
      checkImportEnd: false,
      tracklist_name: '',
      tracklist_cover: '',
      tracklist: [],
      showHelp: false,
    }
  },
  methods: {
    go() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: '#FFFFFF',
        container: this.$refs.loadableButton.$el,
        scale: 0.45
      })

      this.getPoster(this.url)
        .then(data => {
          if (data !== 'Error') {
            this.tracklist_name = data.name;
            this.tracklist_cover = data.image;
            this.error = false;
            setTimeout( ()=> {
              this.playlist = data;
              this.$vs.loading.close(this.$refs.loadableButton.$el);
              this.convert = true;
            }, 1000);
            setTimeout( ()=> {
              this.$vs.loading({
                container: '#div-with-loading',
                type: 'sound',
                scale: 0.6
              });
              this.getPlaylist(this.url).then(data => {
                this.finish = true;
                this.last = false;
                this.tracklist = data;
              });
            }, 1100);
          } else {
            this.$vs.loading.close(this.$refs.loadableButton.$el);
            this.error = true;
          }
        });
    },
    getPoster (id) {
      return axios.post('https://spotya.ru/data.php', {
          url: id,
          type: 'poster',
        })
        .then(function (response) {

          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getPlaylist (id) {
      return axios.post('https://spotya.ru/data.php', {
          url: id,
          type: 'playlist',
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkImport() {
      const imported_tracks = JSON.parse(this.$refs.checkImportJson.value);
      if (imported_tracks.success) {
        const area = this.$refs.tracklist.value;
        var arrayOfLines = area.split('\n');

        imported_tracks.tracks.forEach(function(item) {
          for (var j=0; j<arrayOfLines.length; j++) {
              if (arrayOfLines[j].indexOf(item.title)  > -1) {
                arrayOfLines.splice(j, 1); 
              }
          }
        });
      }
      this.checkImportError = arrayOfLines;
      this.checkImportEnd = true;
    },
    generateCheckId() {
      this.checkImportId = new URL(this.checkImportId).pathname.split('/')[2];
      this.checkImportShowFields = true;
    },
    downloadCover() {
      window.open(this.tracklist_cover, "_blank");
    },
    openUrl() {
      this.showHelp = true;
      this.$refs.tracklist.select();
      document.execCommand('copy');
      window.open("https://music.yandex.ru/import/", "_blank");
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container { 
  height: 200px;
  position: relative;
  height: calc(100% - 140px);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.inputx {
  width: 100%;
}

.logo {
  margin-bottom: 10px;
}

#main-card {
  padding-right: 20px !important;
  padding-top: 20px;
  padding-left: 20px;
}

#convert {
  width: 50%;
  box-shadow: 0 10px 11px -10px #fa915c;
}

#convertend {
  /* width: 50%; */
  box-shadow: 0 10px 11px -10px #fa915c;
  margin-bottom: 5px;
}

#checkImport {
  width: 50%;
  box-shadow: 0 10px 11px -10px #fa915c;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
}

#checkArea {
  border-radius: 7px;
  border-color: #8e8e8e;
  width: 100%;
  height: 100px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.vs-list--slot {
  margin-right: auto;
}

.vs-card--media img {
  margin-bottom: 20px;
}

#finish_text {
  font-size: smaller;
  width: 100%;
}

.last_playlisted {
  position: absolute;
  bottom: 0px;
}

.tracklist {
  display: block;
  width: 100%;
  height: 200px;
  border: 0;
}

.vuesax-app-is-ltr .vs-popup--close {
  font-size: 17px;
}

.api-button {
  margin-left: 10px;
  margin-right: 10px;
}

.botml {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
<style>
  @import '../assets/styles.css';
</style>
