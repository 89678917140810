<template>
  <div id="app">
    <HelloWorld/>

    <section>
        <div class="wave"></div>
    </section>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */


html {
  overflow: hidden;
}

html, #app, body {
  height: 100%;
  font-size: 15pt;
  font-family: 'Raleway', sans-serif;
}

h3 {
  font-size: 12pt;
}

body {
  margin: 0;
  padding: 0;
}
section {
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
}

section .wave {
  position: absolute;
  width: 100%;
  /*height from the svg file*/
  height: 140px;
  bottom: 0;
  left: 0;
  background: url("./assets/sep-wave-orange.svg");
  animation: waves 10s linear infinite;
}

section .wave:before {
  content:'';
  width: 100%;
  height: 140px;
  background: url("./assets/sep-wave-orange.svg");
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  animation: waves-reverse 10s linear infinite;
}

section .wave:after {
  content:'';
  width: 100%;
  height: 140px;
  background: url("./assets/sep-wave-orange.svg");
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  animation-delay: -5s;
  animation: waves 20s linear infinite;
}


@keyframes waves {
  0% {
    background-position: 0;
  }
  100% {
    /* from width of the svg file */
    background-position: 1600px;
  }
}

@keyframes waves-reverse {
  0% {
    background-position: 1600px;
  }
  100% {
    background-position: 0;
  }
}

#app {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.vs-card--media img {
  width: 100%;
}
</style>
